<template>
  <div class="-mx-10 -mt-8 h-screen">
    <div class="border-t border-blue-200 h-full">
      <div class="flex">
        <div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">
          <h1 class="text-2xl font-bold mt-5 px-10 md:px-24">
            Update Password
          </h1>

          <form
            @submit.prevent="submit"
            class="card pt-8 pb-10 px-10 md:px-24"
            key="admin"
          >
            <template v-if="errorMessage">
              <div
                class="rounded-full bg-red-100 p-3 flex flex-row items-center mb-10"
              >
                <div
                  class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3"
                >
                  !
                </div>
                <div class="text-xs font-normal">{{ errorMessage }}</div>
              </div>
            </template>
            <template v-if="getFormError(form)">
              <div class="alert alert-red-soft mb-10">
                <span class="alert-icon">!</span>
                <span>{{ form.error }}</span>
              </div>
            </template>

            <form-group
              type="password"
              name="password"
              v-model="form.data.old_password.value"
              :form="form"
            >
              Current password
            </form-group>

            <form-group
              type="password"
              name="password"
              :form="form"
              v-model="form.data.password.value"
            >
              New password
            </form-group>

            <form-group
              type="password"
              name="confirm_password"
              :form="form"
              v-model="form.data.confirm_password.value"
            >
              Confirm new password
            </form-group>

            <div class="text-left mt-10">
              <button
                type="submit"
                class="button bg-blue-500 hover:bg-blue-600 text-white w-full"
                :disabled="form.loading"
              >
                <span v-if="form.loading">Changing...</span>
                <span v-else>Change Password</span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <modal
        className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
        ref="modal"
      >
        <img
          :src="checkmark"
          class="mx-auto mb-8"
          height="90"
          alt="Check Mark"
        />

        <div class="text-lg font-bold mb-4">
          Success!
        </div>
        <div class="text-xs mb-10">
          Password changed successfully
        </div>

        <router-link
          :to="{ name: 'dashboard' }"
          class="button bg-blue-500 hover:bg-blue-600 text-white"
        >
          Go to Dashboard
        </router-link>
      </modal>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: this.$options.basicForm([
        "old_password",
        "password",
        { name: "confirm_password", rules: "required|same:password" },
      ]),
      checkmark: require("@/assets/checkmark-base.svg"),
    };
  },
  computed: {
    errorMessage() {
      if (this.form.error.toString().match(/Error: Network Error/i)) {
        return "Please check your internet connection";
      }

      const errors = this.form.error?.response?.data?.errors;
      if (errors && Object.keys(errors).length) {
        return "Please check the form for incorrect or missing data";
      }

      return null;
    },
  },
  methods: {
    async submit() {
      if (!this.validateForm(this.form)) {
        return false;
      }

      this.form.loading = true;
      await this.$post({
        url: `${this.$baseurl}/affiliate/profile/change-password`,
        data: {
          old_password: this.form.data.old_password.value,
          password: this.form.data.password.value,
          password_confirmation: this.form.data.confirm_password.value,
        },
        headers: this.headers,
        success: () => {
          this.$refs.modal.open();
        },
        error: (error) => {
          this.form.error = error.response.data.message;
          // this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });
      this.form.loading = false;
    },
  },
};
</script>
